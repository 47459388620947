import React from "react"
import LayoutEng from "../../components/LayoutEng"
import paperPlane from "../../assets/images/paper-airplane.gif"
import SEO from "../../components/SEO"

const Thanks = () => {
  return (
    <LayoutEng>
      <SEO title="Thanks" />
      <main className="thank-you-page">
        <section>
            <div className="thanksImg">
            <img src={paperPlane} alt="Paper plane" />
            </div>
          <h3>Your message has been sent successfully!</h3>
          <h4>Thank you for reaching out! We will respond to you soon.</h4>
        </section>
      </main>
    </LayoutEng>
  )
}

export default Thanks
